import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from '../components/Button.js'

export default function CaseElement({ 
    content, 
    toPath,
    imageSrc,
    partnerLogo,
    ...others
  }) {

  if(!imageSrc) imageSrc = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}
  if(!partnerLogo) partnerLogo = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}

  const image = getImage(imageSrc.localFile)
  const partnerLogoImage = getImage(partnerLogo.localFile)

  return (
    <Button to={toPath} className="dfc w-100">
      <div className="p-r dfc bg-pattern-linear-bottom">
        <GatsbyImage image={image} className="rounded-top card-feature-image" alt={imageSrc.altText} />
        <GatsbyImage image={partnerLogoImage} className="p-a case-partner-logo z1" alt={partnerLogo.altText} />
      </div>
      <div className="bg-color-purple-3 p-4 rounded-bottom h-100">
        <span className="h4 c-purple-1">
          {content}
        </span>
      </div>
    </Button>
  ) 
}
