import * as React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Page from "../elements/Page.js"
import Icon from "../elements/Icon.js"
import Depoimento from "../elements/Depoimento.js"
import CaseElement from "../elements/CaseElement.js"

import FormContatoImageSection from "../section/FormContatoImageSection.js"

import ContainerWithBg from "../helpers/ContainerWithBg.js"
import Container from "../helpers/Container.js"

export default function CaseSinglePage(props) {
  console.log(props)
  const { featuredImage, title, yoastData, mContent } = props.pageContext
  const image = getImage(featuredImage.localFile)

  const cases = mContent.comunicacaoCasesConteudoDepoimentosIndicacoes ?
    mContent.comunicacaoCasesConteudoDepoimentosIndicacoes[0].comunicacaoCasesConteudoDepoimentosIndicacoesCases
    :
    null
  const icones = mContent.comunicacaoCasesConteudoIcones
  const depoimentos = mContent.comunicacaoCasesConteudoDepoimentos
  const itensEsquerda = mContent.comunicacaoCasesConteudoItens

  const itensDireita = mContent.comunicacaoCasesConteudoDireita
  let imageDireita = itensDireita.comunicacaoCasesConteudoDireitaImagem
  if (!imageDireita) imageDireita = { localFile: "../../images/post-image.png", altText: "Placeholder Image" }
  const imgDireita = getImage(imageDireita.localFile)

  const galeria = mContent.comunicacaoCasesConteudoGaleria

  const countImgs = galeria ? galeria.length : 0
  const col = 12 / countImgs

  return (
    <Page>

      <Seo
        title={yoastData.title}
        post={yoastData}
      />

      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-dynamic"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1"
        style={{ backgroundImage: `url(${image.images.fallback.src}` }}
      >
        
        <span className="tag bg-color-golden c-purple m-2 c-p db ttu mb-2 bold">
          case
        </span>
        <h1 className="h1 mb-0">{title}</h1>
      </ContainerWithBg>

      <Container className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="p-4 p-lg-8">
              <div className="col-size-720">
                <div
                  dangerouslySetInnerHTML={{ __html: mContent.comunicacaoCasesConteudoDescricao }}
                  className="the-content pb-5 list-default"
                />
                {/* Itens Esquerda */}
                {itensEsquerda &&
                  itensEsquerda.map((content, index) => (
                    <div key={`content-${index}`}>
                      <h2 className="h2 c-purple title-left-arrow ms-8 ms-lg-0">{content.comunicacaoCasesConteudoItensTitulo}</h2>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.comunicacaoCasesConteudoItensConteudo }}
                        className="the-content list-default pb-5"
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0 bg-color-purple-4 d-flex flex-column justify-content-center pt-8">
            <GatsbyImage image={imgDireita} objectFit="cover" alt={imageDireita.altText} />
            <div className="col-size-720 p-4 p-lg-8">

              <h2 className="h2 c-purple">{itensDireita.comunicacaoCasesConteudoDireitaTitulo &&
                itensDireita.comunicacaoCasesConteudoDireitaTitulo
              }</h2>

              {itensDireita.comunicacaoCasesConteudoDireitaConteudo &&
                <div
                  dangerouslySetInnerHTML={{ __html: itensDireita.comunicacaoCasesConteudoDireitaConteudo }}
                  className="the-content list-default"
                />
              }
            </div>
          </div>
        </div>
      </Container>

      <Container className="container-fluid sep-y">
        <div className="row justify-content-center">
          {
            galeria &&
            galeria.map((content, index) => (
              <div className={`col-12 col-sm-${col} p-0 d-flex`} key={`gallery-${index}`}>
                <GatsbyImage image={getImage(content.localFile)} className="quarter" objectFit="cover" alt={content.altText} />
              </div>
            ))
          }
        </div>
      </Container>

      <Container className="py-6 sep-y">
        <div className="container">
          <h2 className="tac h2 c-purple mb-6">Resultados</h2>
          <div className="row justify-content-center">
            {/* Icones */}
            {icones &&
              icones.map((icone, index) => (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 tac" key={`icon-${index}`}>
                  <Icon
                    type={icone.comunicacaoCasesConteudoIconesIcone}
                    content={icone.comunicacaoCasesConteudoIconesConteudo}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </Container>


      {depoimentos &&
        <Container className="container-fluid c-white p-4 p-lg-8 sep-y">
          <div className="row justify-content-center">
            {/* Depoimento */}
            <h2 className="tac h2 c-purple mb-6">{
              depoimentos.length === 1 ? `Depoimento` : `Depoimentos`
            }</h2>
            {depoimentos &&
              depoimentos.map((dep, index) => (
                <Depoimento
                  key={`dep-${index}`}
                  text={dep.comunicacaoCasesConteudoDepoimentosDepoimento}
                  jobTitle={dep.comunicacaoCasesConteudoDepoimentosTitulo}
                  company={dep.comunicacaoCasesConteudoDepoimentosNome}
                  picture={dep.comunicacaoCasesConteudoDepoimentosImagem}
                  video={dep.comunicacaoCasesConteudoDepoimentosVideo}
                />
              ))
            }
          </div>
      </Container>
    }

      <FormContatoImageSection />
      
      {cases &&
        <Container className="container-fluid c-white sep-y p-4 p-lg-8">
          <h2 className="c-purple tac pb-4">Conheça também outros serviços</h2>
          <div className="row">
            {cases &&
              cases.map((el, key) => (
                <div className="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 d-flex justify-content-center align-items-stretch mb-4" key={key}>
                  <CaseElement
                    content={el.casesFields.comunicacaoCasesDescricao}
                    toPath={el.link}
                    imageSrc={(el.featuredImage) ? el.featuredImage.node : null}
                    partnerLogo={el.casesFields.comunicacaoCasesLogo}
                  />
                </div>
              ))
            }
          </div>
        </Container>
      }
    </Page>
  )
}